import React from "react"
import Page from "../components/page";


const MethodologyPage = (props) => {
  const pageTitle="Rating System Explained";
  return (
    <Page location={props.location.href} pageTitle={pageTitle}>
    <h1>{pageTitle}</h1>
    <h2>Welcome</h2>
    <p>cbbratings.com's system provides an objective, statistical analysis to rank NCAA college basketball teams. 
    Our goal is to offer fans with a reliable, objective measure of team strength in college basketball.</p>

    <h2>Overview</h2>
    <p>Each team's rating is determined soleley by the scores of their games played to date. Winning against stronger opponents can significantly improve a team's rating.
    Unlike traditional rankings, our system takes into account the margin of victory in games.</p>

    <h2>Home Advantage</h2>
    <p>These rankings account for the fact that there is a home court advantage which affects the outcomes of games. 
    When calculating the ratings of each team, this home edge is also determined. </p>

    <h2>Updates</h2>
    <p>We update our ratings daily during the season, reflecting the latest game results.</p>
   
    </Page>
    );
};
export default MethodologyPage;